export enum DashboardColumnTypes {
  Caller = "caller",
  Category = "category",
  Flags = "flags",
  Ratings = "ratings",
  Reason = "reason",
  Date = "date",
}

export enum CallCategories {
  Support = "Support",
  Sales = "Sales",
}
