import SmartLabel from "@/components/common/smartLabel";
import { SupportedCountries } from "@/enums/internationalization";
import { AgencyDirectoryResponse } from "@/types/agencies";
import { GetPhoneNumberForUI } from "@/utils/common/phone";
import { Icon, ICON_TYPE, Tooltip } from "@lula-technologies-inc/lux";
import clsx from "clsx";
import { Badge } from "flowbite-react";

export const LabelValue = (
  label: string,
  value: any,
  labelTooltip?: string,
  valueTooltip?: string,
  onClick?: () => void
): JSX.Element => {
  return (
    <div className={clsx("mt-3")}>
      <div className={clsx("text-xs", "text-gray-500")}>
        {labelTooltip ? (
          <Tooltip className={clsx("min-w-[150px]")} message={labelTooltip}>
            {label}
          </Tooltip>
        ) : (
          label
        )}
      </div>
      <div className={clsx("text-sm")}>
        {value ? (
          valueTooltip ? (
            <Tooltip
              className={clsx("min-w-[150px]")}
              message={valueTooltip}
              placement="bottom"
            >
              {value}
            </Tooltip>
          ) : onClick ? (
            <div
              className={clsx("cursor-pointer", "inline-block")}
              onClick={onClick}
            >
              {value}
            </div>
          ) : (
            <SmartLabel type={label} value={value} />
          )
        ) : (
          <div
            className={clsx(
              "h-2.5",
              "my-0.5",
              "bg-gray-300",
              "rounded-full",
              "w-24",
              "animate-pulse"
            )}
          ></div>
        )}
      </div>
    </div>
  );
};

export const ToBooleanLabel = (value?: string | null): string => {
  return value?.trim().toLowerCase() === "true" ? "Yes" : "No";
};

export const ToResolutionLabel = (value?: string | null): string => {
  if (!value) {
    return "n/a";
  }

  const label = value?.trim().toLowerCase();

  switch (label) {
    case "yes":
    case "resolved":
      return "Resolved";
    case "no":
    case "unresolved":
    case "not resolved":
      return "Unresolved";
    case "transfer":
      return "Transferred";
    default:
      return "n/a";
  }
};

export const ToCompletionLabel = (
  contacts: AgencyDirectoryResponse | null,
  value: string | null | undefined,
  direction: "" | "Inbound" | "Outbound",
  country: SupportedCountries
): string | JSX.Element => {
  if (!value) {
    return "n/a";
  }

  const label = value?.trim().toLowerCase();

  if (label.includes("user")) {
    return `${direction === "Inbound" ? "Caller" : "Callee"} Hangup`;
  } else if (label.includes("assistant")) {
    return "GAIL Hangup";
  } else if (label.includes("transfer")) {
    const split = value.split("to");

    if (split.length > 1) {
      const phoneNumber = split[1].trim();
      const contact = contacts?.data?.find((c) =>
        c.phone_numbers.find((p) => p.phone === phoneNumber)
      );

      if (contact) {
        let transferredTo =
          contact.type === "department"
            ? contact.department
            : `${contact.first_name} ${contact.last_name}`;

        if (contact.type === "individual" && contact.department) {
          transferredTo += ` (${contact.department})`;
        }

        return (
          <div>
            Transferred to {transferredTo}
            <div>{GetPhoneNumberForUI(phoneNumber, country)}</div>
          </div>
        );
      }

      return `Transferred to ${GetPhoneNumberForUI(phoneNumber, country)}`;
    }

    return "Transferred";
  }

  return "n/a";
};

export const ToRating = (
  value?: number,
  allowBounce?: boolean,
  emptyValue?: string
): string | JSX.Element => {
  if (!value || value === 0) {
    return (
      <div className="inline-block">
        {emptyValue ? (
          emptyValue
        ) : (
          <>
            <Icon icon="star" className="text-gray-600 mr-1" />
            <Icon icon="star" className="text-gray-600 mr-1" />
            <Icon icon="star" className="text-gray-600 mr-1" />
            <Icon icon="star" className="text-gray-600 mr-1" />
            <Icon icon="star" className="text-gray-600" />
          </>
        )}
      </div>
    );
  }

  let components = [];

  for (let i = 0; i < 5; i++) {
    components.push(
      <Icon
        icon="star"
        className={clsx(
          { "text-lulaNew": value > i },
          { "text-gray-400": value <= i },
          allowBounce
            ? {
                "animate-bounce": value === 5,
                "animation-delay-100": i === 1,
                "animation-delay-200": i === 2,
                "animation-delay-300": i === 3,
                "animation-delay-400": i === 4,
              }
            : null
        )}
        type={ICON_TYPE.SOLID}
      />
    );
  }

  return (
    <>
      {components.map((c, i) => (
        <div key={i} className={clsx("inline-block", "mr-1")}>
          {c}
        </div>
      ))}
    </>
  );
};

export const ToFlag = (
  value: boolean | undefined,
  isFlagType: boolean,
  commonTranslation: (key: string) => string,
  overrideText?: string
): string | JSX.Element => {
  if (value === undefined) {
    return "";
  }

  const trueColor = isFlagType ? "red" : "green";

  return (
    <Badge
      color={value ? trueColor : "gray"}
      className={clsx(
        "mt-1",
        "uppercase",
        "shadow",
        "whitespace-nowrap",
        "[&>span]:text-center",
        "[&>span]:w-full"
      )}
    >
      <Icon
        icon={isFlagType ? "flag" : "user-magnifying-glass"}
        type={value ? ICON_TYPE.SOLID : ICON_TYPE.REGULAR}
        className={clsx("mr-2", "w-5")}
      />
      {overrideText
        ? overrideText
        : value
        ? commonTranslation("yes")
        : commonTranslation("no")}
    </Badge>
  );
};
